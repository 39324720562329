import React from 'react';
import ReactModal from 'react-modal';
import { useState } from 'react';

import dnarestaurantFancy from '../../assets/images/dnarestaurantfancy.webp';
import dnarestaurantCozy from '../../assets/images/dnarestaurantcozy.webp';
import dnarestaurantFancyFull from '../../assets/images/dnarestaurantfancy-full.webp';
import dnarestaurantCozyFull from '../../assets/images/dnarestaurantcozy-full.webp';
import { useTranslation } from 'react-i18next';

export default function DNARestaurant() {

  const {t} = useTranslation();
  ReactModal.setAppElement('#root');
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function openModal(image) {
    setModalImage(image);
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }
  return (
    
    <div className='dnaRestaurant'>
      <h1 className='dnaRestaurant__title'>{t('Concepts.Restaurant.Title')}</h1>
      <p className='dnaRestaurant__description'>
      {t('Concepts.Restaurant.Content')}
      </p>
      <h2 className='dnaRestaurant__click'>
      {t('Concepts.Restaurant.Click')}
      </h2>
      <div className='dnaRestaurant__preview-image-container'>
        <img
          className='dnaRestaurant__preview-image'
          alt='Preview of DNA Restaurant cozy version landing page on a large desktop'
          src={dnarestaurantFancy}
          onClick={() => {
            openModal(dnarestaurantFancyFull);
          }}
        ></img>
        <img
          className='dnaRestaurant__preview-image'
          alt='Preview of DNA Restaurant cozy version landing page on a large desktop'
          src={dnarestaurantCozy}
          onClick={() => {
            openModal(dnarestaurantCozyFull);
          }}
        ></img>
      </div>
      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
        overlayClassName='Overlay'
        className='modal'
      >
        <div className='modalDiv'>
          <button className='modalClose' onClick={closeModal}>
            X
          </button>
          <div className='projectModal'>
            <img className='dnaRestaurant__modal-image' alt='A visual preview of the Homepage of DNA Restaurant' src={modalImage}></img>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}
