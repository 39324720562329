import React from 'react';
import { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import ReactModal from 'react-modal';
import { useTranslation } from 'react-i18next';

export default function Contact() {
  const {t} = useTranslation();
  ReactModal.setAppElement('#root');
  const form = useRef();

  //Modal Logic
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('modal-title');
  const [modalContent, setModalContent] = useState('modal-info');
  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        `${process.env.REACT_APP_EMAILSERVICE_TOKEN}`,
        `${process.env.REACT_APP_TEMPLATE_TOKEN}`,
        form.current,
        `${process.env.REACT_APP_EMAILSERVICE_APIKEY}`
      )
      .then(
        (result) => {
          closeModal();
          setModalTitle('Success');
          setModalContent(
            <div>
              <p>{t('Contact.Form.Modal.Confirm')}</p>
              <button className='CTAButton' onClick={closeModal}>
              {t('Contact.Form.Modal.Button')}
              </button>
            </div>
          );
          openModal();
        },
        (error) => {
          console.log(error);
          setModalTitle('Error');
          setModalContent(
            <div>
              <p>{t('Contact.Form.Modal.Reject')}</p>
              <button className='CTAButton' onClick={closeModal}>
              {t('Contact.Form.Modal.Button')}
              </button>
            </div>
          );
        }
      );
  };

  return (
    <div className='contact'>
      <h1>Get in touch!</h1>
      <form ref={form} className='contactForm' onSubmit={sendEmail}>
        <div className='contactForm__input-container'>
          <label htmlFor='firstName' className='contactForm__label'>
            {t('Contact.Form.FirstName')}
          </label>
          <input
            id='firstName'
            className='contactForm__input'
            name='firstName'
            required
          />
        </div>
        <div className='contactForm__input-container'>
          <label htmlFor='lastName' className='contactForm__label'>
          {t('Contact.Form.LastName')}
          </label>
          <input
            id='lastName'
            className='contactForm__input'
            name='lastName'
            required
          />
        </div>
        <div className='contactForm__input-container'>
          <label htmlFor='company' className='contactForm__label'>
          {t('Contact.Form.Company')}
          </label>
          <input
            id='company'
            className='contactForm__input'
            name='company'
            required
          />
        </div>
        <div className='contactForm__input-container'>
          <label htmlFor='email' className='contactForm__label'>
          {t('Contact.Form.Email')}
          </label>
          <input
            id='email'
            className='contactForm__input'
            type='email'
            name='email'
            required
          />
        </div>
        <div className='contactForm__input-container'>
          <label htmlFor='phone' className='contactForm__label'>
          {t('Contact.Form.Phone')}
          </label>
          <input
            id='phone'
            className='contactForm__input'
            name='phone'
            type='tel'
            required
          />
        </div>
        <div className='contactForm__input-container'>
          <label htmlFor='message' className='contactForm__label'>
          {t('Contact.Form.Message')}
          </label>
          <textarea
            id='message'
            className='contactForm__input contactForm__input-large'
            name='message'
            required
          />
        </div>
        <button className='CTAButton' type='submit'>
        {t('Contact.Form.Button')}
        </button>
      </form>
      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Modal'
        overlayClassName='Overlay'
        className='modal'
      >
        <div className='modalDiv'>
          <h2 className='modal__title'>{modalTitle}</h2>
          {modalContent}
        </div>
      </ReactModal>
    </div>
  );
}
