import React from 'react';
import { Link } from 'react-router-dom';
import twotonebackdrop from '../assets/graphics/twotonebackdrop.webp';
import twotonebackdropblue from '../assets/graphics/twotonebackdropblue.webp';
import dnarestaurantmockup from '../assets/images/dnarestaurantmockup.webp';
import dnabakerymockup from '../assets/images/dnabakerymockup.webp';
import { useTranslation } from 'react-i18next';

export default function Concepts() {
  const { t } = useTranslation();
  return (
    <div className='works'>
      <section
        className='works__project works__project'
        style={{
          background: `url(${twotonebackdropblue})`,
          backgroundSize: 'cover',
        }}
      >
        <div className='works__project-text-container'>
          <p className='works__project-type'>{t('Concepts.Section1.Type')}</p>
          <h2>DNA Restaurant</h2>
          <p className='works__project-description'>
            {t('Concepts.Section1.Content')}
          </p>
          <Link
            to='/work/concepts/dnarestaurant'
            className='works__project-button CTAButton'
          >
            {t('Concepts.Button')}
          </Link>
        </div>
        <div className='works__project-image-container'>
          <img
            className='works__project-image'
            id='altidroneimage'
            alt='preview of the project'
            src={dnarestaurantmockup}
          ></img>
        </div>
      </section>

      <section
        className='works__project works__project-inverse'
        style={{
          background: `url(${twotonebackdrop})`,
          backgroundSize: 'cover',
        }}
      >
        <div className='works__project-text-container works__project-text-container-inverse'>
          <p className='works__project-type'>{t('Concepts.Section2.Type')}</p>
          <h2>{t('Concepts.Section2.Title')}</h2>
          <p className='works__project-description'>
            {t('Concepts.Section2.Content')}
          </p>
          <Link
            to='/work/concepts/dnabakery'
            className='works__project-button CTAButton'
          >
            {t('Concepts.Button')}
          </Link>
        </div>
        <div className='works__project-image-container'>
          <img
            className='works__project-image'
            id='altidroneimage'
            src={dnabakerymockup}
            alt='preview of the project'
          ></img>
        </div>
      </section>

      {/* <section
        className='works__project works__project'
        style={{
          background: `url(${twotonebackdropblue})`,
          backgroundSize: 'cover',
        }}
      >
        <div className='works__project-text-container'>
          <p className='works__project-type'>Web App</p>
          <h2>Altidrone Productions</h2>
          <p className='works__project-description'>
            Altidrone Productions provides a drone imaging service in
            south-eastern France. DNA Dev Studios took their existing website
            and refurbished it to modern standards and now takes care of the
            maintenance of the site.
          </p>
          <button className='works__project-button CTAButton'>
            Visit Website
          </button>
        </div>
        <div className='works__project-image-container'>
          <img
            className='works__project-image'
            id='altidroneimage'
            src={altidroneipad}
            alt='preview of the project'
          ></img>
        </div>
      </section> */}
    </div>
  );
}
