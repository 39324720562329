import DefaultLayout from './components/Layouts/DefaultLayout';
import Homepage from './pages/Homepage';
import { Routes, Route } from 'react-router-dom';
import Works from './pages/Works';
import Project from './pages/Project';
import Contact from './pages/Contact';
import Careers from './pages/Careers';
import Team from './pages/Team';
import About from './pages/About';
import OurBrand from './pages/OurBrand';
import SimpleWebsites from './pages/SimpleWebsites';
import E404 from './pages/E404';
import WebApps from './pages/WebApps';
import Concepts from './pages/Concepts';
import DNARestaurant from './pages/concept/DNARestaurant';
import DNABakery from './pages/concept/DNABakery';


function App() {
  localStorage.setItem('lang', 'en');
  return (
    <Routes>
      <Route exact path='/' element={<DefaultLayout />}>
        <Route index element={<Homepage />} />
        <Route path='/story' element={<About />} />
        <Route path='/mission' element={<OurBrand />} />
        <Route path='/work' element={<Works />} />
        <Route path='/work/simplewebsites' element={<SimpleWebsites />} />
        <Route path='/work/webapps' element={<WebApps />} />
        <Route path='/work/concepts' element={<Concepts />} />
        <Route path='/work/concepts/dnarestaurant' element={<DNARestaurant />} />
        <Route path='/work/concepts/dnabakery' element={<DNABakery />} />
        <Route path='/project' element={<Project />} />
        <Route path='/contact' element={<Contact />} />
        <Route path='/careers' element={<Careers />} />
        <Route path='/team' element={<Team />} />
        <Route path='*' element={<E404 />} />
      </Route>
    </Routes>
  );
}

export default App;
