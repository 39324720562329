import React from 'react';
import heroVideo from '../assets/videos/webdesignskyscrapers.mp4';
import videoPoster from '../assets/images/cogs.webp';
import animationVideo from '../assets/videos/webbuildillustration.mp4';
import foodTablet from '../assets/videos/foodtablet.mp4';
import planningBoard from '../assets/videos/planningBoard.mp4';
import webBlueprint from '../assets/videos/webblueprint.mp4';
import { Link } from 'react-router-dom';
import { Reveal } from '../components/Animation/Reveal';
import { useTranslation } from 'react-i18next';

export default function OurBrand() {
  const { t } = useTranslation();

  console.log(webBlueprint)

  return (
    <div className='mission'>
      <section className='mission__hero' id='mission'>
        <div className='mission__hero-title-container'>
          <h1 className='mission__hero-title'>{t('Mission.Title')}</h1>
        </div>
        <video
          webkit-playsInline
          playsInline
          className='mission__heroVideo'
          preload='none'
          poster={videoPoster}
          autoPlay
          loop
          muted
        >
          <source src={heroVideo} type='video/mp4' />
        </video>
      </section>
      <section className='mission__values'>
        <h2 className='mission__subtitle'>{t('Mission.Values.Title')}</h2>
        <Reveal>
          <div className='mission__values-container'>
            <h3 className='mission__values-subtitle'>{t('Mission.Values.Section1.Title')}</h3>
            <p>{t('Mission.Values.Section1.Content')}</p>
          </div>
        </Reveal>
        <Reveal classes='mission__values-reveal-center'>
          <div className='mission__values-container mission__values-container-center'>
            <h3 className='mission__values-subtitle'>{t('Mission.Values.Section2.Title')}</h3>
            <p>{t('Mission.Values.Section2.Content')}</p>
          </div>
        </Reveal>
        <Reveal classes='mission__values-reveal-right'>
          <div className='mission__values-container mission__values-container-end'>
            <h3 className='mission__values-subtitle'>{t('Mission.Values.Section3.Title')}</h3>
            <p>{t('Mission.Values.Section3.Content')}</p>
          </div>
        </Reveal>
        <Reveal classes='mission__values-reveal-center'>
          <div className='mission__values-container mission__values-container-center'>
            <h3 className='mission__values-subtitle'>{t('Mission.Values.Section4.Title')}</h3>
            <p>{t('Mission.Values.Section4.Content')}</p>
          </div>
        </Reveal>
        <Reveal>
          <div className='mission__values-container'>
            <h3 className='mission__values-subtitle'>{t('Mission.Values.Section5.Title')}</h3>
            <p>{t('Mission.Values.Section5.Content')}</p>
          </div>
        </Reveal>
      </section>
      <section className='mission__fulfillment'>
        <h2 className='mission__subtitle'>{t('Mission.Mission.Title')}</h2>
        <div className='mission__fulfillment-content'>
          <div className='mission__fulfillment-container'>
            <h3 className='mission__fulfillment-subtitle'>{t('Mission.Mission.Section1.Title')}</h3>
            <p className='mission__fulfillment-text'>{t('Mission.Mission.Section1.Content')}</p>
          </div>
          <video
            webkit-playsInline
            playsInline
            className='mission__fulfillment-video'
            preload='none'
            poster={videoPoster}
            autoPlay
            loop
            muted
          >
            <source src={animationVideo} type='video/mp4' />
          </video>
          <div className='mission__fulfillment-container mission__fulfillment-container-inverse'>
            <h3 className='mission__fulfillment-subtitle'>{t('Mission.Mission.Section2.Title')}</h3>
            <p className='mission__fulfillment-text'>{t('Mission.Mission.Section2.Content')}</p>
          </div>
          <video
            webkit-playsInline
            playsInline
            className='mission__fulfillment-video'
            preload='none'
            poster={videoPoster}
            autoPlay
            loop
            muted
          >
            <source src={foodTablet} type='video/mp4' />
          </video>
          <div className='mission__fulfillment-container'>
            <h3 className='mission__fulfillment-subtitle'>{t('Mission.Mission.Section3.Title')}</h3>
            <p className='mission__fulfillment-text'>{t('Mission.Mission.Section3.Content')}</p>
          </div>
          <video
            webkit-playsInline
            playsInline
            className='mission__fulfillment-video'
            preload='none'
            poster={videoPoster}
            autoPlay
            loop
            muted
          >
            <source src={planningBoard} type='video/mp4' />
          </video>
          <div className='mission__fulfillment-container mission__fulfillment-container-inverse'>
            <h3 className='mission__fulfillment-subtitle'>{t('Mission.Mission.Section4.Title')}</h3>
            <p className='mission__fulfillment-text'>{t('Mission.Mission.Section4.Content')}</p>
          </div>
          <video
            webkit-playsInline
            playsInline
            className='mission__fulfillment-video'
            preload='none'
            poster={videoPoster}
            autoPlay
            loop
            muted
          >
            <source src={webBlueprint} type='video/mp4' />
          </video>
        </div>
      </section>

      <section className='mission__join'>
        <h2 className='mission__subtitle'>{t('Mission.Join.Title')}</h2>
        <p className='mission__join-text'>{t('Mission.Join.Content')}</p>
        <div className='mission__join-button-container'>
          <Link to='/work' className='CTAButton mission__join-button'>
            {t('Mission.Join.Buttons.Offers')}
          </Link>
          <Link to='/careers' className='CTAButton mission__join-button'>
            {t('Mission.Join.Buttons.Careers')}
          </Link>
        </div>
      </section>
    </div>
  );
}
