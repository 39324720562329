import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import whitelogo from '../assets/images/logos/logo_vertical_white.svg';
import coverImage from '../assets/images/01_iPad_Mockup.webp';
import cafe from '../assets/graphics/Cafe.webp';
import webdevpng from '../assets/graphics/webdevgraphic.webp';
import meetingonline from '../assets/graphics/meetingonline.webp';

export default function Homepage() {
  const { t } = useTranslation();

  return (
    <div className='homepage'>
      <section className='hero'>
        <img
          className='hero__image'
          src={coverImage}
          alt='A tablet with various websites made by DNA Dev Studios'
        ></img>
        <div className='hero__text'>
          <img
            className='hero__logo'
            src={whitelogo}
            alt='=dna dev studios logo'
          />
          <h1 className='hero__title'>{t('Homepage.Hero.Title')}</h1>
          <p className='hero__smalltext'>{t('Homepage.Hero.Content')}</p>
          <div className='hero__buttons'>
            <Link
              to='/project'
              className='CTAButton CTAButton-dark homepage__linkButton'
              aria-label='Link to the processes page'
            >
              {t('Homepage.Hero.Buttons.LearnMore')}
            </Link>
            <Link to='/contact' className='CTAButton homepage__linkButton'>
              {t('Homepage.Hero.Buttons.Quote')}
            </Link>
          </div>
        </div>
      </section>

      <section className='homepage__growbusiness homepage__section'>
        {/* <div className='threeDElement__container'>


          <Suspense fallback={<div>Loading...</div>}>
          <Test3dComponent
              component={
                'https://prod.spline.design/phOZU88UmCk0UJ2N/scene.splinecode'
              }
            />
          </Suspense>
        </div> */}
        <img
          className='homepage__section-image'
          src={cafe}
          alt='Graphic of a coffee shop'
        />
        <div className='homepage__growbusiness-textContainer'>
          <h2>{t('Homepage.Section1.Title')}</h2>
          <h3 className='homepage__growbusiness-text'>
            {t('Homepage.Section1.Content')}
          </h3>

          <Link to='/project' className='CTAButton homepage__linkButton'>
            {t('Homepage.Section1.Button')}
          </Link>
        </div>
      </section>

      <section className='homepage__process homepage__section'>
        <div className='homepage__process-textContainer'>
          <h2>{t('Homepage.Section2.Title')}</h2>
          <h3 className='homepage__process-text'>
            {t('Homepage.Section2.Content')}
          </h3>

          <Link to='/project' className='CTAButton homepage__linkButton'>
            {t('Homepage.Section2.Button')}
          </Link>
        </div>
        <img
          className='homepage__section-image'
          src={meetingonline}
          alt='3d illustration of various meeting and planning'
        />
        {/* <div className='threeDElement__container'>
        <img
            className='homepage__section-image'
            src={meetingonline}
            alt='3d illustration of various meeting and planning'
          />

          {/* <Suspense fallback={<div>Loading...</div>}>
            <Test3dComponent
              component={
                'https://prod.spline.design/w8spXLwFE0Hgx8am/scene.splinecode'
              }
            />
          </Suspense>
        </div> */}
      </section>

      <section className='homepage__services homepage__section'>
        <img
          className='homepage__section-image'
          src={webdevpng}
          alt='3D Illustration of web development'
        />
        {/* <div className='threeDElement__container'>
        <img
            className='homepage__section-image'
            src={webdevpng}
            alt='3D Illustration of web development'
          />
          <Suspense fallback={<div>Loading...</div>}>
            <Test3dComponent
              component={
                'https://prod.spline.design/2ZL2gq3HxLDsSScg/scene.splinecode'
              }
            />
          </Suspense>
        </div> */}
        <div className='homepage__services-textContainer'>
          <h2>{t('Homepage.Section3.Title')}</h2>
          <h3 className='homepage__services-text'>
            {t('Homepage.Section3.Content')}
          </h3>

          <Link to='/work' className='CTAButton homepage__linkButton'>
            {t('Homepage.Section3.Button')}
          </Link>
        </div>
      </section>
    </div>
  );
}
