import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function Footer() {
  const {t} = useTranslation();
  return (
    <footer className='footer'>
      <div className='footer__container'>
        <h1 className='footer__title'>DNA Dev Studios</h1>
        {/* <Link className='footer__link' to='/'>What's New</Link>
        <Link className='footer__link' to='/'>Our Team</Link> */}
        <Link className='footer__link' to='/work'>{t('Footer.Section1.Offers')}</Link>
        <Link className='footer__link' to='/contact'>{t('Footer.Section1.Contact')}</Link>
      </div>
      <div className='footer__container'>
      <h1 className='footer__title'>{t('Footer.Section2.Title')}</h1>
        <Link className='footer__link' to='/work/webapps'>{t('Footer.Section2.SimpleWeb')}</Link>
        <Link className='footer__link' to='/work/simplewebsites'>{t('Footer.Section2.WA')}</Link>
        <Link className='footer__link' to='/work/concepts'>{t('Footer.Section2.Concepts')}</Link>
      </div>
      <div className='footer__container'>
      <h1 className='footer__title'>{t('Footer.Section3.Title')}</h1>
        {/* <Link className='footer__link' to='/'>Stories</Link> */}
        {/* <Link className='footer__link' to='/'>Press</Link> */}
        <Link className='footer__link' to='/careers'>{t('Footer.Section3.Careers')}</Link>
        {/* <Link className='footer__link' to='/'>Our Brand</Link> */}
      </div>
      <div className='footer__container'>
      <h1 className='footer__title'>{t('Footer.Section4.Title')}</h1>
        <Link className='footer__link' to='https://www.instagram.com/dnadevstudios/' target='_blank'>Instagram</Link>
        <Link className='footer__link' to='https://www.linkedin.com/company/dna-dev-studios' target='_blank'>Linkedin</Link>
        <Link className='footer__link' to='https://twitter.com/dnadevstudios' target='_blank'>Twitter</Link>
        <Link className='footer__link' to='https://www.facebook.com/profile.php?id=61558582838924' target='_blank'>Facebook</Link>
      </div>
    </footer>
  )
}
