import React from 'react';
import Blob from '../components/Animation/Blob';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import videoPoster from '../assets/images/cogs.webp';
import seaVideo from '../assets/videos/story/mediterranean1.mp4';
import webdevconcept from '../assets/videos/story/webdevconcept.mp4';
import webdevisom from '../assets/videos/story/webdevisom.mp4';
import headerVideo from '../assets/videos/story/header.mp4';

export default function About() {
  const { t } = useTranslation();
  return (
    <div className='story'>
      <header className='story__header'>
        <h1 className='story__title'>{t('Story.Title')}</h1>
        <video
          webkit-playsInline
          playsInline
          className='story__header-video'
          preload='none'
          poster={videoPoster}
          autoPlay
          loop
          muted
        >
          <source src={headerVideo} type='video/mp4' />
        </video>
      </header>

      <div className='story__section-1'>
        <section className='story__section'>
          <h2 className='story__subtitle'>{t('Story.Section1.Title')}</h2>
          <p className='story__text'>{t('Story.Section1.Content')}</p>
        </section>
        <video
          webkit-playsInline
          playsInline
          className='story__video2 story__video2--elevated'
          preload='none'
          poster={videoPoster}
          autoPlay
          loop
          muted
        >
          <source src={webdevisom} type='video/mp4' />
        </video>
      </div>
      <div className='story__section-1 story__section-1--inverse'>
        <video
          webkit-playsInline
          playsInline
          className='story__video2'
          preload='none'
          poster={videoPoster}
          autoPlay
          loop
          muted
        >
          <source src={webdevconcept} type='video/mp4' />
        </video>

        <section className='story__section'>
          <h2 className='story__subtitle'>{t('Story.Section2.Title')}</h2>
          <p className='story__text'>
          {t('Story.Section2.Content')}
          </p>
        </section>
      </div>

      <div className='story__section-1'>
        <section className='story__section'>
          <h2 className='story__subtitle'>{t('Story.Section3.Title')}</h2>
          <p className='story__text'>
          {t('Story.Section3.Content')}
          </p>
        </section>
        <video
          webkit-playsInline
          playsInline
          className='story__video2 story__video2--elevated'
          preload='none'
          poster={videoPoster}
          autoPlay
          loop
          muted
        >
          <source src={seaVideo} type='video/mp4' />
        </video>
      </div>

      <section className='story__section story__section--centered'>
        <h2 className='story__subtitle'>{t('Story.Section4.Title')}</h2>
        <p className='story__text story__text--centered'>
        {t('Story.Section4.Content')}
        </p>
        <Link className='CTAButton' to='/work'>
          {t('Story.Button')}
        </Link>
      </section>
      <Blob />
    </div>
  );
}
