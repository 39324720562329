import React, { useEffect } from 'react'

export default function Blob() {
  let blob
  useEffect(() => {
    blob = document.querySelector(".blob");
    document.body.onpointermove = event => {
        const {clientX , clientY} = event;
        blob.animate({
            left: `${clientX}px`,
            top: `${clientY}px`
        }, {duration: 3000, fill: 'forwards'})
    }
  }, [blob])
    
  return (
    <div>
        <div className='blob'></div>
    </div>
  )
}
