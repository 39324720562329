import React from 'react';
import { Link } from 'react-router-dom';
import whitelogoonly from '../assets/images/logos/logo_symbol_white.svg';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';

export default function Navbar() {
  const { t } = useTranslation();
  return (
    <nav className='navbar'>
      <div className='navbar__menu'>
        <Link to='/' className='navbar__logoContainer'>
          <img
            src={whitelogoonly}
            className='navbar__logo'
            alt='dna dev studios logo'
          />
        </Link>
        <div className='navbar__navigation'>
          <div className='navbar__menuItem'>
            {t('Navbar.About.Title')}
            <ul className='navbar__menuDropdown'>
              <li className='navbar__menuLI'>
                <Link to='/story' className='navbar__menuDropdown-link'>
                  {t('Navbar.About.Story')}
                </Link>
              </li>
              <li className='navbar__menuLI'>
                <Link to='/mission' className='navbar__menuDropdown-link'>
                  {t('Navbar.About.Mission')}
                </Link>
              </li>
              <li className='navbar__menuLI'>
                <Link to='/work' className='navbar__menuDropdown-link'>
                  {t('Navbar.About.Work')}
                </Link>
              </li>
            </ul>
          </div>
          <div className='navbar__menuItem'>
            {t('Navbar.Explore.Title')}
            <ul className='navbar__menuDropdown'>
              <li className='navbar__menuLI'>
                <Link
                  to='/work/simplewebsites'
                  className='navbar__menuDropdown-link'
                >
                  {t('Navbar.Explore.SimpleWeb')}
                </Link>
              </li>
              <li className='navbar__menuLI'>
                <Link to='/work/webapps' className='navbar__menuDropdown-link'>
                  {t('Navbar.Explore.WA')}
                </Link>
              </li>
              <li className='navbar__menuLI'>
                <Link to='/work/concepts' className='navbar__menuDropdown-link'>
                  {t('Navbar.Explore.Concepts')}
                </Link>
              </li>
              <li className='navbar__menuLI'>
                <Link to='/project' className='navbar__menuDropdown-link'>
                  {t('Navbar.Explore.Process')}
                </Link>
              </li>
            </ul>
          </div>
          <div className='navbar__menuItem'>
            {t('Navbar.Contact.Title')}
            <ul className='navbar__menuDropdown'>
              <li className='navbar__menuLI'>
                <Link to='/contact' className='navbar__menuDropdown-link'>
                  {t('Navbar.Contact.Inquiry')}
                </Link>
              </li>
              <li className='navbar__menuLI'>
                <Link to='/contact' className='navbar__menuDropdown-link'>
                  {t('Navbar.Contact.Support')}
                </Link>
              </li>
            </ul>
          </div>
          <div className='navbar__menuItem'>
            {t('Navbar.Language')}
            <ul className='navbar__menuDropdown'>
              <li className='navbar__menuLI'>
                <LanguageSelector></LanguageSelector>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className='navbar__navigation'>
          <DropdownMenu
            title={t('Navbar.About.Title')}
            items={[
              <Link to='/story' className='dropdown__link'>
                {t('Navbar.About.Story')}
              </Link>,
              <Link to='/mission' className='dropdown__link'>
                {t('Navbar.About.Mission')}
              </Link>,
              <Link to='/work' className='dropdown__link'>
                {t('Navbar.About.Work')}
              </Link>,
              // <Link to='/team' className='dropdown__link'>
              //   Our Team
              // </Link>,
            ]}
          />
          <DropdownMenu
            title={t('Navbar.Explore.Title')}
            items={[
              <Link to='/work/simplewebsites' className='dropdown__link'>
                {t('Navbar.Explore.SimpleWeb')}
              </Link>,
              <Link to='/work/webapps' className='dropdown__link'>
                {t('Navbar.Explore.WA')}
              </Link>,
              <Link to='/work/concepts' className='dropdown__link'>
                {t('Navbar.Explore.Concepts')}
              </Link>,
              <Link to='/project' className='dropdown__link'>
                {t('Navbar.Explore.Process')}
              </Link>,
            ]}
          />

          <DropdownMenu
            title={t('Navbar.Contact.Title')}
            items={[
              <Link to='/contact' className='dropdown__link'>
                {t('Navbar.Contact.Inquiry')}
              </Link>,
              <Link to='/contact' className='dropdown__link'>
                {t('Navbar.Contact.Support')}
              </Link>
            ]}
          />
          
          <DropdownMenu
            title={t('Navbar.Language')}
            items={[
              <LanguageSelector></LanguageSelector>
            ]}
          />
          
        </div> */}
      </div>
    </nav>
  );
}
