import React from 'react'
import { Link } from 'react-router-dom'

export default function E404() {
  return (
    <div className='error404'>
      <div className='error404__container'>
        <h1 className='error404__404'>404</h1>
        <p className='error404__text'>
          Whoops! The page you're looking for doesn't exist!
        </p>
        <Link to='/' className='error404__Link'>
          Return to the Home Page
        </Link>
      </div>
    </div>
  )
}
