import React from 'react';
import { Link } from 'react-router-dom';

import twotonebackdrop from '../assets/graphics/twotonebackdrop.webp';
import twotonebackdropblue from '../assets/graphics/twotonebackdropblue.webp';
import altidroneipad from '../assets/images/altidroneipad.webp';
import juliannorrismockup from '../assets/images/juliannorrismockup.webp';
import { useTranslation } from 'react-i18next';

export default function SimpleWebsites() {
  const {t} = useTranslation();
  return (
    <div className='works'>
      <section
        className='works__project works__project'
        style={{
          background: `url(${twotonebackdropblue})`,
          backgroundSize: 'cover',
        }}
      >
        <div className='works__project-text-container'>
          <p className='works__project-type'>{t('SimpleWeb.Section1.Type')}</p>
          <h2>Altidrone Productions</h2>
          <p className='works__project-description'>
          {t('SimpleWeb.Section1.Content')}
          </p>
          <Link to='https://main.d1b2tzq3s1j15q.amplifyapp.com/' target='_blank' className='works__project-button CTAButton'>
          {t('SimpleWeb.Button')}
          </Link>
        </div>
        <div className='works__project-image-container'>
          <img
            className='works__project-image'
            id='altidroneimage'
            src={altidroneipad}
            alt='Project Preview'
          ></img>
        </div>
      </section>

      <section
        className='works__project works__project-inverse'
        style={{
          background: `url(${twotonebackdrop})`,
          backgroundSize: 'cover',
        }}
      >
        <div className='works__project-text-container works__project-text-container-inverse'>
          <p className='works__project-type'>{t('SimpleWeb.Section2.Type')}</p>
          <h2>Julian Norris Portfolio</h2>
          <p className='works__project-description'>
          {t('SimpleWeb.Section2.Content')}
          </p>
          <Link to='https://www.juliannorris.com' target='_blank' className='works__project-button CTAButton'>
          {t('SimpleWeb.Button')}
          </Link>
        </div>
        <div className='works__project-image-container'>
          <img
            className='works__project-image'
            id='altidroneimage'
            src={juliannorrismockup}
            alt='Project Preview'
          ></img>
        </div>
      </section>
    </div>
  );
}
