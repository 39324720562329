import React from 'react';
import { Link } from 'react-router-dom';

import bookiphone from '../assets/images/bookiphone.avif';
import twotonebackdrop from '../assets/graphics/twotonebackdrop.webp';
import twotonebackdropblue from '../assets/graphics/twotonebackdropblue.webp';
import altidroneipad from '../assets/images/altidroneipad.webp';
import juliannorrismockup from '../assets/images/juliannorrismockup.webp';
import { useTranslation } from 'react-i18next';

export default function Works() {
  const {t} = useTranslation();
  return (
    <div className='works'>
      <h1>{t('Work.Title')}</h1>
      <section className='works__project works__project-1' style={{background:`url(${twotonebackdrop})`, backgroundSize: 'cover'}}>
        <div className='works__project-image-container'>
          <img className='works__project-image' src={bookiphone} alt='Project Preview'></img>
        </div>
        <div className='works__project-text-container' >
          <h2>{t('Work.WebApps.Title')}</h2>
          <p className='works__project-type'>{t('Work.WebApps.Content')}</p>
          <p className='works__project-description'></p>
          <Link to='/work/webapps' className='works__project-button CTAButton'>
          {t('Work.Button')}
          </Link>
        </div>
      </section>

      <section className='works__project works__project-2' style={{background:`url(${twotonebackdropblue})`, backgroundSize: 'cover'}}>
        <div className='works__project-text-container'>
          <h2>{t('Work.SimpleWeb.Title')}</h2>
          <p className='works__project-type'>{t('Work.SimpleWeb.Content')}</p>
          <p className='works__project-description'>

          </p>
          <Link to='/work/simplewebsites' className='works__project-button CTAButton'>
          {t('Work.Button')}
          </Link>
        </div>
        <div className='works__project-image-container'>
          <img className='works__project-image' src={juliannorrismockup} alt='Project Preview'></img>
        </div>
      </section>

      <section className='works__project works__project-3' style={{background:`url(${twotonebackdrop})`, backgroundSize: 'cover'}}>
        <div className='works__project-image-container'>
          <img className='works__project-image' id='altidroneimage' src={altidroneipad} alt='Project Preview'></img>
        </div>
        <div className='works__project-text-container'>
          <h2>{t('Work.Concepts.Title')}</h2>
          <p className='works__project-type'>{t('Work.Concepts.Content')}</p>
          <p className='works__project-description'>
          </p>
          <Link to='/work/concepts' className='works__project-button CTAButton'>
          {t('Work.Button')}
          </Link>
        </div>
      </section>
    </div>
  );
}
