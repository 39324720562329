import React from 'react'
import { useTranslation } from 'react-i18next'

import dnabakeryconcept from '../../assets/images/dnabakeryfull.webp';

export default function DNABakery() {
  const {t} = useTranslation();
  return (
    <div className='dnaRestaurant'>
      <h1 className='dnaRestaurant__title'>{t('Concepts.Bakery.Title')}</h1>
      <p className='dnaRestaurant__description'>
      {t('Concepts.Bakery.Content')}
      </p>
      {/* <h2 className='dnaRestaurant__click'>
      {t('Concepts.Bakery.Click')}
      </h2> */}
      <img className='dnaBakery__image' src={dnabakeryconcept} alt='Concept mockup of DNA Bakery'></img>
    </div>
  )
}
