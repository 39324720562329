import React from 'react';
import { Link } from 'react-router-dom';

import twotonebackdrop from '../assets/graphics/twotonebackdrop.webp';
import twotonebackdropblue from '../assets/graphics/twotonebackdropblue.webp';
import toinepcsmockup from '../assets/images/toinepcsmockup.webp';
import isacmtblogmockup from '../assets/images/isacmtblogmockup.webp';
import { useTranslation } from 'react-i18next';

export default function WebApps() {
  const {t} = useTranslation();
  return (
    <div className='works'>
      <section
        className='works__project works__project'
        style={{
          background: `url(${twotonebackdropblue})`,
          backgroundSize: 'cover',
        }}
      >
        <div className='works__project-text-container'>
          <p className='works__project-type'>{t('WebApps.Section1.Type')}</p>
          <h2>Toine PCs</h2>
          <p className='works__project-description'>
            {t('WebApps.Section1.Content')}
          </p>
          <Link to='https://www.toinepcs.ca' target='_blank' className='works__project-button CTAButton'>
            {t('WebApps.Button')}
          </Link>
        </div>
        <div className='works__project-image-container'>
          <img
            className='works__project-image'
            id='altidroneimage'
            src={toinepcsmockup}
            alt='A preview of the Toine PCs website on a large desktop monitor'
          ></img>
        </div>
      </section>

      <section
        className='works__project works__project-inverse'
        style={{
          background: `url(${twotonebackdrop})`,
          backgroundSize: 'cover',
        }}
      >
        <div className='works__project-text-container works__project-text-container-inverse'>
          <p className='works__project-type'>{t('WebApps.Section2.Type')}</p>
          <h2>Artventures in France</h2>
          <p className='works__project-description'>
            {t('WebApps.Section2.Content')}
          </p>
          <button className='works__project-button CTAButton CTAButton--disabled' disabled='true'>
            {t('WebApps.Button')}
          </button>
        </div>
        <div className='works__project-image-container'>
          <img
            className='works__project-image'
            id='altidroneimage'
            src={isacmtblogmockup}
            alt='A preview of the Artventures in France homepage on a mockup mac'
          ></img>
        </div>
      </section>

      {/* <section
        className='works__project works__project'
        style={{
          background: `url(${twotonebackdropblue})`,
          backgroundSize: 'cover',
        }}
      >
        <div className='works__project-text-container'>
          <p className='works__project-type'>Web App</p>
          <h2>Altidrone Productions</h2>
          <p className='works__project-description'>
            Altidrone Productions provides a drone imaging service in
            south-eastern France. DNA Dev Studios took their existing website
            and refurbished it to modern standards and now takes care of the
            maintenance of the site.
          </p>
          <button className='works__project-button CTAButton'>
            Visit Website
          </button>
        </div>
        <div className='works__project-image-container'>
          <img
            className='works__project-image'
            id='altidroneimage'
            src={altidroneipad}
            alt='Project Preview'
          ></img>
        </div>
      </section> */}
    </div>
  );
}
