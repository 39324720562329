import React from 'react';
import { Link } from 'react-router-dom';
import tempcoverimage from '../assets/images/toinepcsmockup.webp';
import BackgroundFX from '../components/Reusable/BackgroundFX';
import cogs from '../assets/images/cogs.webp';
import { useTranslation } from 'react-i18next';

export default function Project() {
  const { t } = useTranslation();
  return (
    <div className='project'>
      <BackgroundFX />
      <div className='project__blackoutbar' />
      <section className='project__hero'>
        <div className='project__hero-textContainer'>
          <h1 className='project__title'>{t('Process.Hero.Title')}</h1>
          <h2 className='project__description'>
            {t('Process.Hero.Content1')}
            <br />
            {t('Process.Hero.Content2')}
          </h2>
          <a className='CTAButton project__hero-button' href='#planning'>
            {t('Process.Hero.Button')}
          </a>
        </div>
        <img className='project__hero-image' src={tempcoverimage} alt='' />
      </section>

      <section className='project__section project__planning' id='planning'>
        <p className='project__step'>{t('Step')} 1</p>
        <h2 className='project__section-title'>
          {t('Process.Section1.Title')}
        </h2>
        <p className='project__section-description'>
          {t('Process.Section1.Content')}
        </p>
        <div className='project__section-container'>
          <article className='project__section-subsection'>
            <h3 className='project__section-subtitle'>
              {t('Process.Section1.Card1.Title')}
            </h3>
            <p className='project__section-text'>
              {t('Process.Section1.Card1.Content')}
            </p>
          </article>
          <article className='project__section-subsection'>
            <h3 className='project__section-subtitle'>
              {t('Process.Section1.Card2.Title')}
            </h3>
            <p className='project__section-text'>
              {t('Process.Section1.Card2.Content')}
            </p>
          </article>
          <article className='project__section-subsection'>
            <h3 className='project__section-subtitle'>
              {t('Process.Section1.Card3.Title')}
            </h3>
            <p className='project__section-text'>
              {t('Process.Section1.Card3.Content')}
            </p>
          </article>
          <article className='project__section-subsection'>
            <h3 className='project__section-subtitle'>
              {t('Process.Section1.Card4.Title')}
            </h3>
            <p className='project__section-text'>
              {t('Process.Section1.Card4.Content')}
            </p>
          </article>
        </div>
        <a className='CTAButton project__nextbutton' href='#design'>
          {t('Process.Step')} 2
        </a>
      </section>

      <img className='project__cogs' src={cogs} alt='decorative cogs' />

      <section className='project__section project__design' id='design'>
        <p className='project__step'>{t('Process.Step')} 2</p>
        <h2 className='project__section-title'>
          {t('Process.Section2.Title')}
        </h2>
        <p className='project__section-description'>
          {t('Process.Section2.Content')}
        </p>
        <a className='CTAButton project__nextbutton' href='#development'>
          {t('Process.Step')} 3
        </a>
      </section>

      <img className='project__cogs' src={cogs} alt='decorative cogs' />

      <section
        className='project__section project__development'
        id='development'
      >
        <p className='project__step'>{t('Process.Step')} 3</p>
        <h2 className='project__section-title'>
          {t('Process.Section3.Title')}
        </h2>
        <p className='project__section-description'>
          {t('Process.Section3.Content')}
        </p>
        <a className='CTAButton project__nextbutton' href='#maintenance'>
          {t('Process.Step')} 4
        </a>
      </section>

      <img className='project__cogs' src={cogs} alt='decorative cogs' />

      <section
        className='project__section project__maintenance'
        id='maintenance'
      >
        <p className='project__step'>{t('Process.Step')} 4</p>
        <h2 className='project__section-title'>
          {t('Process.Section4.Title')}
        </h2>
        <p className='project__section-description'>
          {t('Process.Section4.Content')}
        </p>
        <Link className='CTAButton project__nextbutton' to='/contact'>
          {t('Process.Section4.Button')}
        </Link>
      </section>
    </div>
  );
}
